import React from "react";
import './RightSidebar.css';

function RightSidebar() {
    return (
        <div className="rightSidebar">

            <div className="support">

                <div className="supportIcons">
                    <div className="supportIcon">
                        <i class="fa-brands fa-telegram"></i>
                    </div>
                    <div className="supportIcon">
                        <i class="fa-brands fa-discord"></i>
                    </div>
                    <div className="supportIcon">
                        <i class="fa-brands fa-skype"></i>
                    </div>
                    <div className="supportIcon">
                        <i class="fa-solid fa-comments"></i>
                    </div>
                </div>
            </div>

            <div className="gameContent">
                <h1>POPULAR CASINO GAMES</h1>

                <div className="gameImages">
                    <img src="./images/home/slot1.svg" />
                    <img src="./images/home/slot2.gif" />
                </div>
                <div className="gameImages">
                    <img src="./images/home/slot3.svg" />
                    <img src="./images/home/slot4.svg" />
                </div>
                <div className="gameImages">
                    <img src="./images/home/slot5.svg" />
                    <img src="./images/home/slot6.svg" />
                </div>
                <div className="gameImages">
                    <img src="./images/home/slot7.svg" />
                    <img src="./images/home/slot8.svg" />
                </div>
                <div className="gameImages">
                    <img src="./images/home/slot2.gif" />
                    <img src="./images/home/slot3.svg" />
                </div>
                <div className="gameImages">
                    <img src="./images/home/slot9.svg" />
                    <img src="./images/home/slot1.svg" />
                </div>
            </div>

        </div>
    );
}

export default RightSidebar;