import React from "react";
import './Header.css';

function Header() {
    return (
        <header>
            <div>
                <img src="/images/logo.png" alt="Gamblix Logo" id="logo" />
            </div>

            <button id="appDownload"><i class="fa-brands fa-apple"></i>MOBILE APP</button>

            <div className="mainMenu">
                <ul>
                    <li><i class="fa-solid fa-futbol"></i>SPORTS BETTING</li>
                    <li><i class="fa-regular fa-clock"></i>LIVE BETTING</li>
                    <li><i class="fa-solid fa-gem"></i>CASINO</li>
                    <li><i class="fa-solid fa-diamond"></i>LIVE CASINO</li>
                    <li><i class="fa-solid fa-horse-head"></i>RACING</li>
                    <li><i class="fa-solid fa-rocket"></i>CRASH GAMES</li>
                    <li><i class="fa-solid fa-gift"></i>PROMOTIONS</li>
                    <li><i class="fa-solid fa-trophy"></i>TOURNAMENTS</li>
                    <li><i class="fa-brands fa-whatsapp"></i>LIVE SUPPORT</li>
                </ul>

            </div>

            <div>
                <button id="loginButton">
                    <i class="fa-solid fa-key"></i> LOG IN
                </button>

                <button id="signupButton">
                    <i class="fa-solid fa-user-plus"></i> SIGN UP
                </button>
            </div>
        </header>
    );
}

export default Header;
