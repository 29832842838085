import React, { useState } from "react";
import "./Description.css";

const Description = () => {
    const [expanded, setExpanded] = useState(false);

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    return (
        <div className={`description ${expanded ? "expanded" : ""}`}>
            <h1>
                Welcome to Gamblix, the ultimate destination for live sports betting and online casino.
            </h1>

            <p>
                Enjoy betting not only on football but also on tennis, basketball, and all your favorite sports. Gamblix offers exclusive daily promotions and bonuses for both sports betting and casino games. Get access to top features like "Best Odds on Major Leagues," "Goal Parade," "Boosted Odds," and the cashback promo “Tactical Sports Bonus.”
            </p>

            <h2>
                Gamblix: Sports Betting and Casino with an Edge
            </h2>

            <p>
                Gamblix is a trusted brand in [Your Country], offering the best sports betting experience, including Serie A, Premier League, Champions League, and more. Bet on live events as they happen, with competitive odds on football, tennis, basketball, and other major sports. We also feature a complete online casino with slots, table games, card games, crash games, and sports-themed games.
            </p>

            {expanded && (
                <>
                    <h2>Your Home for Live Sports Betting</h2>
                    <p>
                        Whether you prefer pre-match or live betting, Gamblix has you covered. Explore betting options for football, including Serie A, Serie B, Serie C, Coppa Italia, and top European championships. We also feature options for horse racing, Totocalcio, and innovative virtual sports betting, giving you even more ways to play.
                    </p>

                    <h2>Predictions, Odds, and Betting Types</h2>
                    <p>
                        Check out the Gamblix Arena for expert football predictions, updated odds, and a wide range of betting options. From 1X2 and Double Chance to Over/Under, Handicap, Exact Score, and Multiples, there’s a bet type for every strategy and player.
                    </p>

                    <h2>Live Betting Thrills</h2>
                    <p>
                        Experience the adrenaline of live betting! With [Your Site Name], you can place bets during live matches and events, immersing yourself in the action while it unfolds. Our live betting section lets you feel like part of the game with real-time updates and dynamic odds.
                    </p>

                    <h2>A Complete Online Casino</h2>
                    <p>
                        Visit the casino section for an extensive collection of games, including live dealer options like Poker, Blackjack, Roulette, and Monopoly Live. Try our themed slots, Megaways games, and jackpot slots, with categories like traditional bar slots, Hold and Win, and "Book of" series, including the iconic Book of Dead.
                    </p>

                    <h2>Welcome Bonuses and Daily Promotions</h2>
                    <p>
                        [Your Site Name] offers incredible welcome bonuses for new players. Enjoy up to €500 across your first three deposits or take advantage of exclusive daily offers like free spins, cashback, and boosted odds. If you’re a football fan, unlock special bonuses when registering with the email used for your sports streaming subscription.
                    </p>

                    <h2>Play Anytime, Anywhere</h2>
                    <p>
                        Access [Your Site Name] from any device—smartphone, tablet, or desktop. Our platform is fully optimized for mobile play, with fast-loading pages and a user-friendly interface. Download the app for Android or iOS and enjoy betting or playing casino games anytime, anywhere.
                    </p>

                    <h2>Secure Gaming and Trusted Payments</h2>
                    <p>
                        [Your Site Name] operates under a fully licensed and regulated platform, ensuring top-level security and fair play. With encrypted data and a wide range of payment methods, including VISA, Mastercard, PayPal, and Apple Pay, managing your funds is quick and safe.
                    </p>

                    <h2>Responsible Gaming</h2>
                    <p>
                        We encourage responsible gaming and offer tools to help you play safely, including deposit limits and self-exclusion options. Gambling is restricted to those 18 years or older. For more information, visit our Responsible Gaming page.
                    </p>
                </>
            )}

            <div className="toggle-button" onClick={toggleExpand}>
                {expanded ? "Collapse ↑" : "Expand ↓"}
            </div>
        </div>
    );
};

export default Description;
