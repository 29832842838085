import './App.css';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import RightSidebar from './components/RightSidebar';
import Home from './pages/casinoPages/Home';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

function Layout() {
  const location = useLocation();


  return (
    <>
      <Header />
      <Sidebar />
      <Home />
      <RightSidebar />
      <Routes>
        {/*<Route path="/privacy-policy" element={<PrivacyPolicy />} */}
      </Routes>
    </>
  );
}

function App() {
  return (
    <Router>
      <Layout />
    </Router>
  );
}

export default App;
