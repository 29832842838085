import React from 'react';
import './Home.css';
import { NavLink } from 'react-router-dom';
import Description from '../../components/Description';

function Home() {

    return (

        <div>

            <div className="homeMain1">
                <img src="/images/home/banners/banner2.png" />
            </div>

            <div className="homeMain">

                <div className='titleContainer'>
                    <h1>Recommended For You</h1>
                    <h2>SEE MORE</h2>
                </div>
                <div className="gameContainer">
                    <div>
                        <img src="./images/home/slot1.svg" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                    <div>
                        <img src="./images/home/slot2.gif" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                    <div>
                        <img src="./images/home/slot3.svg" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                    <div>
                        <img src="./images/home/slot4.svg" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                    <div>
                        <img src="./images/home/slot5.svg" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                    <div>
                        <img src="./images/home/slot6.svg" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                    <div>
                        <img src="./images/home/slot7.svg" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                    <div>
                        <img src="./images/home/slot8.svg" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                    <div>
                        <img src="./images/home/slot1.svg" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                    <div>
                        <img src="./images/home/slot9.svg" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                </div>
            </div>

            <div className="homeMain">

                <div className='titleContainer'>
                    <h1>Booster Odds</h1>
                    <h2>SEE MORE</h2>
                </div>

                <div className="odds">
                    <div className="betContainer">
                        <div>
                            <p id="category">Champions League</p>
                            <p id="date">10-12-2024 18:45</p>
                            <h1>GIRONA<br></br>LIVERPOOL</h1>
                            <div className="resultsContainer">
                                <div className="result">
                                    <h3>1</h3>
                                    <h4>2.75</h4>
                                </div>

                                <div className="result">
                                    <h3>X</h3>
                                    <h4>3.45</h4>
                                </div>

                                <div className="result">
                                    <h3>2</h3>
                                    <h4>2.48</h4>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="betContainer">
                        <div>
                            <p id="category">Champions League</p>
                            <p id="date">10-12-2024 18:45</p>
                            <h1>DINAMO ZAGABRIA<br></br>CELTIC</h1>
                            <div className="resultsContainer">
                                <div className="result">
                                    <h3>1</h3>
                                    <h4>2.75</h4>
                                </div>

                                <div className="result">
                                    <h3>X</h3>
                                    <h4>3.45</h4>
                                </div>

                                <div className="result">
                                    <h3>2</h3>
                                    <h4>2.48</h4>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="betContainer">
                        <div>
                            <p id="category">Conference League</p>
                            <p id="date">10-12-2024 18:45</p>
                            <h1>ASTANA<br></br>CHELSEA</h1>
                            <div className="resultsContainer">
                                <div className="result">
                                    <h3>1</h3>
                                    <h4>2.75</h4>
                                </div>

                                <div className="result">
                                    <h3>X</h3>
                                    <h4>3.45</h4>
                                </div>

                                <div className="result">
                                    <h3>2</h3>
                                    <h4>2.48</h4>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="betContainer">
                        <div>
                            <p id="category">Europa League</p>
                            <p id="date">10-12-2024 18:45</p>
                            <h1>MALMOE<br></br>GALATASARAY</h1>
                            <div className="resultsContainer">
                                <div className="result">
                                    <h3>1</h3>
                                    <h4>2.75</h4>
                                </div>

                                <div className="result">
                                    <h3>X</h3>
                                    <h4>3.45</h4>
                                </div>

                                <div className="result">
                                    <h3>2</h3>
                                    <h4>2.48</h4>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>


            <div className="homeMain">

                <div className='titleContainer'>
                    <h1>Live Casino</h1>
                    <h2>SEE MORE</h2>
                </div>
                <div className="gameContainer">
                    <div>
                        <img src="./images/home/live1.svg" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                    <div>
                        <img src="./images/home/live2.svg" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                    <div>
                        <img src="./images/home/live3.svg" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                    <div>
                        <img src="./images/home/live4.svg" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                    <div>
                        <img src="./images/home/live5.svg" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                    <div>
                        <img src="./images/home/live6.svg" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                    <div>
                        <img src="./images/home/live7.svg" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                    <div>
                        <img src="./images/home/live8.svg" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                    <div>
                        <img src="./images/home/live9.svg" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                    <div>
                        <img src="./images/home/live1.svg" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                </div>

            </div>

            <div className="homeMain">

                <div className='titleContainer'>
                    <h1>Live Casino</h1>
                    <h2>SEE MORE</h2>
                </div>
                <div className="gameContainer">
                    <div>
                        <img src="./images/home/live1.svg" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                    <div>
                        <img src="./images/home/live2.svg" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                    <div>
                        <img src="./images/home/live3.svg" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                    <div>
                        <img src="./images/home/live4.svg" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                    <div>
                        <img src="./images/home/live5.svg" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                    <div>
                        <img src="./images/home/live6.svg" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                    <div>
                        <img src="./images/home/live7.svg" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                    <div>
                        <img src="./images/home/live8.svg" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                    <div>
                        <img src="./images/home/live9.svg" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                    <div>
                        <img src="./images/home/live1.svg" />
                        <h3>Gates of Olympus</h3>
                        <h4>Pragmatic Play</h4>
                    </div>
                </div>

            </div>

            <div className="slotWeek">

                <div className="slotWeekImg">
                    <img src="./images/home/1slotWeek1.jpg" />
                    <img src="./images/home/slotWeek3.jpg" />
                    <img src="./images/home/slotWeek4.jpg" />
                    <img src="./images/home/slotWeek5.jpg" />
                    <img src="./images/home/slotWeek6.jpg" />
                    <img src="./images/home/slotWeek7.jpg" />
                    <img src="./images/home/slotWeek8.jpg" />
                    <img src="./images/home/1slotWeek1.jpg" />
                </div>
            </div>

            <Description />

        </div>

    );
}

export default Home;



