import React from "react";
import './Sidebar.css';

function Sidebar() {
    return (
        <div className="sidebar">
            <div className="fortuneWheel">
                <img src="/images/fortuneWheel.webp" alt="Gamblix Logo" id="logo" className="image" />
                <h3>FORTUNE WHEEL</h3>
            </div>

            <div className="bonus">
                <img src="/images/moneyicons.webp" alt="Gamblix Logo" id="logo" className="image" />
                <div className="titles">
                    <h2>$10,000</h2>
                    <h3>WEEKLY PRIZE</h3>
                </div>
            </div>

            <div className="search">
                <input type="text" placeholder="Search" className="searchBar" />
                <i className="fa-solid fa-magnifying-glass searchIcon"></i>
            </div>

            <div className="sidebarMenu">
                <h4>QUICK LINKS</h4>
                <ul>
                    <li>
                        <img src="/images/sidebarIcons/home.svg" alt="Home Icon" className="icon" />
                        Home
                    </li>
                    <li>
                        <img src="/images/sidebarIcons/favorites.svg" alt="Home Icon" className="icon" />
                        Favorites
                    </li>
                    <li>
                        <img src="/images/sidebarIcons/live.svg" alt="Home Icon" className="icon" />
                        Live
                    </li>
                    <li>
                        <img src="/images/sidebarIcons/sport.svg" alt="Home Icon" className="icon" />
                        Sport
                    </li>
                    <li>
                        <img src="/images/sidebarIcons/liveCasino.svg" alt="Home Icon" className="icon" />
                        Live Casino
                    </li>
                    <li>
                        <img src="/images/sidebarIcons/racing.svg" alt="Home Icon" className="icon" />
                        Racing
                    </li>
                    <li>
                        <img src="/images/sidebarIcons/promotions.svg" alt="Home Icon" className="icon" />
                        Promotions
                    </li>
                </ul>
            </div>

            <div className="sidebarMenu">

                <h5>POPULAR</h5>
                <ul>
                    <li>
                        <img src="/images/flags/england.svg" alt="Home Icon" className="icon" />
                        Premier League
                    </li>
                    <li>
                        <img src="/images/flags/laliga.svg" alt="Home Icon" className="icon" />
                        La Liga
                    </li>
                    <li>
                        <img src="/images/flags/it.svg" alt="Home Icon" className="icon" />
                        Serie A
                    </li>
                    <li>
                        <img src="/images/flags/fr.svg" alt="Home Icon" className="icon" />
                        Ligue 1
                    </li>
                    <li>
                        <img src="/images/flags/de.svg" alt="Home Icon" className="icon" />
                        Bundesliga
                    </li>
                    <li>
                        <img src="/images/flags/it.svg" alt="Home Icon" className="icon" />
                        Coppa Italia
                    </li>
                    <li>
                        <img src="/images/flags/es.svg" alt="Home Icon" className="icon" />
                        Copa del Rey
                    </li>
                    <li>
                        <img src="/images/flags/africa_cup_of_nations.svg" alt="Home Icon" className="icon" />
                        CAF Champions League
                    </li>
                    <li>
                        <img src="/images/flags/pt.svg" alt="Home Icon" className="icon" />
                        Portugal Primeira Liga
                    </li>
                    <li>
                        <img src="/images/flags/ar.svg" alt="Home Icon" className="icon" />
                        Argentina Primera Division
                    </li>
                    <li>
                        <img src="/images/flags/br.svg" alt="Home Icon" className="icon" />
                        Brazil Serie A
                    </li>
                    <li>
                        <img src="/images/flags/NBA.svg" alt="Home Icon" className="icon" />
                        NBA
                    </li>
                </ul>

            </div>



        </div>
    );
}

export default Sidebar;